<template>
  <div class="en_add">
    <el-card>
      <el-form
        ref="form"
        :model="form"
        :rules="rule"
        label-width="100px"
        :inline="false"
        size="normal"
      >
        <el-form-item label="缓存">
          <el-radio v-model="form.type" :label="0">全部</el-radio>
          <el-radio v-model="form.type" label="activity">活动</el-radio>
          <el-radio v-model="form.type" label="article">文章</el-radio>
          <el-radio v-model="form.type" label="report">报告</el-radio>
          <el-radio v-model="form.type" label="vip">VIP会员</el-radio>
          <el-radio v-model="form.type" label="user">会员</el-radio>
          <el-radio v-model="form.type" label="enterprise">企业</el-radio>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="onSubmit">{{
            id ? '修改' : '清除'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import imgup from '../../components/upImg/index.vue'
import rules from '../../utils/rules'
import imgups from '../../components/uplist/index.vue'
export default {
  components: {
    imgup,
    imgups
  },
  data() {
    return {
      id: null,
      form: {
        type: 0,
      },
      rule: {
        type: [rules.req('请输入商品名称')]
      },
      action: {
        action: 'bonus'
      },
      province: {},
      city: {},
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      cardList: []
    }
  },
  computed: {},
  created() {
    // sessionStorage.setItem('action', 'bonus')
    // this.getform()
    // this.getEnv()
  },
  methods: {
    async onSubmit() {
      var url='/admin/SysConfig/clearCache'

      var { data: res } = await this.$http.post(url, {
        ...this.form,
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
      }
    }
  }
}
</script>

<style lang="less">
.en_add {
  .box {
    display: inline-block;
    width: 300px;
    input {
      border-color: #dcdfe6 !important;
    }
  }
}
</style>
